import React from 'react';
import { ReactComponent as BalloonIcon } from 'assets/svg/balloon.svg';

type Props = {
  text: string;
  paddingleft?: string;
};

const Balloon = (props: Props) => {
  const { text, paddingleft } = props;

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute' }}>
        <BalloonIcon />
      </div>
      <div
        style={{
          paddingLeft: paddingleft,
          alignItems: 'center',
          display: 'flex',
          height: '48px',
          justifyContent: 'left',
          left: '23px',
          position: 'absolute',
          top: '10px',
          width: '150px',
        }}
      >
        <div
          style={{
            color: '#333029',
            fontSize: '16px',
            fontWeight: 800,
            textAlign: 'left',
          }}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

export default Balloon;
