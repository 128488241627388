import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ReactGA from 'react-ga';
import Throbber from 'components/organisms/Throbber';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/stable';
import packageJson from '../package.json';

const ReduxWrapper = lazy(() => import('./Root'));
const { version } = packageJson;

Sentry.init({
  // TODO: add this to env setup
  dsn: process.env.REACT_APP_SENTRY_DSN,
  normalizeDepth: 10,
  environment: process.env.NODE_ENV,
  release: version,
  // debug: 'off',
  beforeSend(event, hint) {
    // https://github.com/getsentry/sentry-react-native/issues/391#issuecomment-431344211
    if (
      event.message &&
      event.message.startsWith('Non-Error exception captured') &&
      hint?.originalException
    ) {
      Sentry.withScope(scope => {
        scope.setExtra('nonErrorException', true);
        Sentry.captureException(hint?.originalException);
      });

      return null;
    }
    const { breadcrumbs = [] } = event;

    return {
      ...event,
      breadcrumbs: breadcrumbs.filter(
        breadCrumb => breadCrumb.category !== 'console',
      ),
    };
  },
});

if (!process.env.REACT_APP_SENTRY_DSN) {
  // eslint-disable-next-line no-console
  console.error('Sentry DSN not defined');
}

ReactGA.initialize('UA-164676055-1');

ReactDOM.render(
  <Suspense
    fallback={
      <div
        style={{
          background: '#ffd800',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: ' 100vh',
        }}
      >
        <Throbber />
      </div>
    }
  >
    <ReduxWrapper />
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
