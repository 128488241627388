import React, { useEffect, useState } from 'react';
import { ReactComponent as Logo } from 'assets/svg/werewolf-logo.svg';
import Balloon from 'components/atoms/Balloon';

const Throbber = () => {
  const [dots, setDots] = useState('.');

  const mount = () => {
    const intervalId = setInterval(() => {
      if (dots.length > 2) {
        setDots('.');
      } else {
        setDots(`${dots}.`);
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  };

  useEffect(mount, [dots]);

  return (
    <div style={{ display: 'flex', width: '270px' }}>
      <div style={{ marginTop: '18px' }}>
        <Logo />
      </div>
      <div style={{ marginLeft: '2px' }}>
        <Balloon text={`Now Loading${dots}`} paddingleft="15px" />
      </div>
    </div>
  );
};

export default Throbber;
